<template>

  <!-- Authors Table Card -->
  <a-card :bordered="false">
    <a-table :columns="columns" :data-source="data" :pagination="false" :showHeader="false">

      <template slot="position" slot-scope="position">

        <div class="tradeinfo">
        <a-row type="flex" justify="start">
          <a-col :span="4" class="instid">
            <span>{{ position.instId }}</span>
          </a-col>
          <a-col :span="4" class="inst-info" style="margin-left: 10px">
            <span>{{ position.mgnMode == 'cross' ? '全仓' : '逐仓' }}</span>
            <span :class="position.posSide == 'long' ? 'tag-up-long' : 'tag-up-short'"> {{ position.posSide == 'long' ? '多' : '空'}} </span>
            <span>{{ position.lever }}x</span>
          </a-col>

        </a-row>
        </div>
        <div style="margin-top: 10px">
        <a-row type="flex" justify="space-between">
          <a-col :span="6">
            <span class="title">开仓时间</span> <span class="text">{{ position.cTime }}</span>
          </a-col>
          <a-col :span="6">
            <span class="title">开仓均价</span> <span class="text">{{ position.openAvgPx }} USDT</span>
          </a-col>
          <a-col :span="6">
            <span class="title">浮动收益率</span> <span class="text" :style="{ color: position.uplRatio >= 0 ? '#31bd65' : '#eb4b6d' }">{{ position.uplRatio }}%</span>
          </a-col>
        </a-row>
        </div>
        <div style="margin-top: 10px">
        <a-row type="flex" justify="space-between">
          <a-col :span="6">
            <span class="title">当前保证金</span> <span class="text">{{ position.imr }} USDT</span>
          </a-col>
          <a-col :span="6">
            <span class="title">实现收益</span> <span class="text" :style="{ color: position.pnl >= 0 ? '#31bd65' : '#eb4b6d' }">{{ position.pnl }} USDT</span>
          </a-col>
          <a-col :span="6">
            <span class="title">浮动收益</span> <span class="text" :style="{ color: position.upl >= 0 ? '#31bd65' : '#eb4b6d' }">{{ position.upl }} USDT</span>
          </a-col>
        </a-row>
        </div>
      </template>


    </a-table>
  </a-card>
  <!-- / Authors Table Card -->

</template>

<script>

export default ({
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {

    }
  },
})

</script>

<style lang="scss">
  .tradeinfo {

    .instid {
      font-weight: bold;
      font-size: 18px!important;
    }
    .inst-info {
      font-size: 12px;
      color: #8c8c8c;
      margin-left: -20px;
      margin-top: 5px!important;
      span {
        margin-right: 5px;

      }
      .tag-up-long {
        background-color: rgba(#31bd65,.1);
        color: #31bd65;
        border-radius: 2px;
      }
      .tag-up-short {
        background-color: rgba(#eb4b6d,.1);
        color: #eb4b6d;
        border-radius: 2px;
      }

    }


  }
  .text {
    font-weight: bold!important;
  }
  .title {
    color: #8c8c8c;
  }

</style>