<template>

  <!-- Authors Table Card -->
  <a-card :bordered="false">
    <a-table :columns="columns" :data-source="data" :pagination="false" :showHeader="false">

      <template slot="history" slot-scope="history">

        <div class="tradeinfo">
        <a-row type="flex" justify="start">
          <a-col :span="4" class="instid">
            <span>{{ history.instId }}</span>
          </a-col>
          <a-col :span="4" class="inst-info" style="margin-left: 10px">
            <span>{{ history.mgnMode == 'cross' ? '全仓' : '逐仓' }}</span>
            <span :class="history.posSide == 'long' ? 'tag-up-long' : 'tag-up-short'"> {{ history.posSide == 'long' ? '多' : '空'}} </span>
            <span>{{ history.lever }}x</span>
          </a-col>

        </a-row>
        </div>
        <div style="margin-top: 10px">
        <a-row type="flex" justify="space-between">
          <a-col :span="6">
            <span class="title">开仓时间</span> <span class="text">{{ history.cTime }}</span>
          </a-col>
          <a-col :span="6">
            <span class="title">开仓均价</span> <span class="text">{{ history.openAvgPx }} USDT</span>
          </a-col>
          <a-col :span="6">
            <span class="title">收益</span> <span class="text" :style="{ color: history.pnl >= 0 ? '#31bd65' : '#eb4b6d' }">{{ history.pnl }}</span>
          </a-col>
          <a-col :span="6">
            <span class="title">收益率</span> <span class="text" :style="{ color: history.pnlRatio >= 0 ? '#31bd65' : '#eb4b6d' }">{{ history.pnlRatio }}%</span>
          </a-col>

        </a-row>
        </div>
        <div style="margin-top: 10px">
        <a-row type="flex" justify="space-between">
          <a-col :span="6">
            <span class="title">平仓时间</span> <span class="text">{{ history.uTime }}</span>
          </a-col>
          <a-col :span="6">
            <span class="title">平仓均价</span> <span class="text">{{ history.closeAvgPx }} USDT</span>
          </a-col>
          <a-col :span="6">
            <span class="title">最大保证金</span> <span class="text">{{ history.imr }} USDT</span>
          </a-col>
          <a-col :span="6"></a-col>

        </a-row>
        </div>
      </template>


    </a-table>
  </a-card>
  <!-- / Authors Table Card -->

</template>

<script>

export default ({
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {

    }
  },
})

</script>

<style lang="scss">
.tradeinfo {

  .instid {
    font-weight: bold;
    font-size: 18px!important;
  }
  .inst-info {
    font-size: 12px;
    color: #8c8c8c;
    margin-left: -20px;
    margin-top: 5px!important;
    span {
      margin-right: 5px;

    }
    .tag-up-long {
      background-color: rgba(#31bd65,.1);
      color: #31bd65;
      border-radius: 2px;
    }
    .tag-up-short {
      background-color: rgba(#eb4b6d,.1);
      color: #eb4b6d;
      border-radius: 2px;
    }

  }


}
.text {
  font-weight: bold!important;
}
.title {
  color: #8c8c8c;
}

</style>